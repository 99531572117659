@import "~react-toastify/dist/ReactToastify.css";

@import "~tippy.js/dist/tippy.css";
@import "~react-phone-input-2/lib/style.css";

@import "~react-datepicker/dist/react-datepicker.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "toast";
@import "collapse";
@import "external";

#root {
	height: 100vh;
}

body {
	overflow-x: hidden;
}
