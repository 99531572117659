/*!
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

// React DatePicker
.react-datepicker {
    &__header {
        @apply border-none bg-transparent;
    }

    &__current-month {
        @apply text-gray-900 pt-0.5 pb-2.5;
    }

    &__navigation {
        @apply top-5 text-gray-600 rounded-md dark:text-gray-300 hover:text-gray-900 dark:hover:text-white hover:border-gray-300 dark:hover:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 focus:ring-gray-100 dark:focus:ring-gray-500 transition duration-100 #{!important};

        &--previous {
            @apply left-5;
        }

        &--next {
            @apply right-5;
        }
    }

    &__navigation-icon {
        @apply inset-0;
        @apply before:border-gray-900 #{!important};
        @apply before:inset-0;

        &:before {
            top: 11px;
        }

        &--previous:before {
            left: -3px;
        }

        &--next:before {
            left: -6px;
        }
    }

    &__month {
        @apply m-0;
    }

    &__day {
        @apply text-gray-600 font-medium rounded-md dark:text-gray-900 hover:border-gray-300 dark:hover:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 focus:ring-gray-100 dark:focus:ring-gray-500 transition duration-100;

        &--selected {
            @apply text-white bg-primary-600 #{!important};
        }

        &--today, &--keyboard-selected {
            @apply bg-transparent;
        }

        &--outside-month {
            @apply text-gray-400 #{!important};
        }

        &--disabled {
            @apply text-gray-400 hover:bg-transparent #{!important};
        }
    }

    &-time {
        &__input-container {
            @apply flex-grow;
        }

        &__input {
            @apply w-full m-0 #{!important};

            & input {
                @apply w-full #{!important};
            }
        }

        &__caption {
            @apply dark:text-gray-900 font-medium;
        }
    }

    &__input-time-container {
        @apply m-0 flex items-center gap-2 pt-2.5;
    }
}
